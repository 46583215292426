import React, { Component } from 'react'  
import { Segment,  Image, Label, Container, Icon } from 'semantic-ui-react'  

export class SupportView extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    // This view require to be authenticated 
    componentDidMount() {
    }

    render() {  

        return ( 
            <Container>
                <Segment basic textAlign='center'>
                <Image centered rounded size='small' src='img/logo-padelr-2048.png' />
                </Segment>
                <Segment basic>
                    <Container text>
                    <p>
                    Pour toute question à propos de l'application, ou des données que nous utilisons, vous pouvez nous contacter à l'adresse suivante
                    </p>
                    </Container>
                </Segment>
                <Segment basic>
                    <Label icon size='small' color='blue'><Icon name='mail' />support at padelr dot app</Label>
                </Segment>
            </Container>    
        )  
    }  
}
export default SupportView
