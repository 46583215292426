import React, { Component } from 'react'  
import { Segment,  Image, Header, Label, Container, Icon } from 'semantic-ui-react'  

export class MainView extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    // This view require to be authenticated 
    componentDidMount() {
    }

    goToSupport() {
        window.location.href = "/support"
    }

    goToPrivacy() {
        window.location.href = "/privacy"
    }

    goToAppStore() {
        window.location.href = "https://apps.apple.com/app/padelr/id6657992480"
    }


    render() {  

        return ( 
            <Container fluid >
                <Segment basic textAlign='center' className='segmentfull'>
                <Image centered rounded size='small' src='img/logo-padelr-2048.png' />
                </Segment>

                <Segment basic>
                    <Header size='large'>Recherchez votre prochain tournoi avec Palder</Header>
                    <Header size='small'>Disponible sur l'App store Apple et bientôt sur Google Play store</Header>
                    <Image as='a' onClick={this.goToAppStore} spaced inline centered size='small' src='img/download_appstore.png'/> <Image as='a' spaced inline centered size='small' src='img/download_playstore.png' disabled/>
                    <Header size='small' color='blue'>Contact: contact at padelr dot app</Header>
                    <Label icon as='a' color='blue' onClick={this.goToSupport}><Icon name='help circle' />Support</Label>
                    <Label icon as='a' color='blue' onClick={this.goToPrivacy}><Icon name='privacy' />Privacy</Label>
                </Segment>
            </Container>    
        )  
    }  
}
export default MainView
