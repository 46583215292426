import './App.css';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainView from './views/Main'
import SupportView from './views/Support';
import PrivacyView from './views/Privacy';



function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainView />} />
          <Route path='/support' element={<SupportView />} />
          <Route path='/privacy' element={<PrivacyView />} />
        </Routes>
      </BrowserRouter>
    
    </div>
  )

}

export default App;
